/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@include mat-core();

// F7D802
$mat-yellow: (
  50: #fffde7,
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #F0D848,
  500: #F0D848,
  600: #F0D848,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  A100: #ffff8d,
  A200: #ffff00,
  A400: #ffea00,
  A700: #ffd600,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$candy-app-primary: mat-palette($mat-yellow);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

body {
  background: #170F13;
  font-family: 'Overpass Mono', monospace;
  color: #000;
  background: url('/assets/img/yaybg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media only screen and (max-width: 600px) {
  body {
    background: url('/assets/img/yaybg-mobile-long.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}


h1, h2, h3 {
  font-family: 'Overpass', sans-serif;
  font-weight: 900;
  color: #EED238;

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

}

p {
  font-size: 22px;
}

a.btn, button.btn {
  font-family: 'Overpass Mono', monospace;
  background: #F0D848;
  border: 0px solid #979797;
  //box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 2px 0 0 #FFFFFF;
  padding: 10px 30px;
  border-radius: 60px;
  color: #000;
  text-decoration: none;
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}

button.btn[disabled] {
  opacity: 0.5;
}

img {
  max-width: 100%;
}
hr {border:0;border-top:1px solid #555;margin:0;height:1px;}

@media only screen and (max-width: 600px) {
  p {
    font-size: 16px;
  }
  a.btn {
    font-size: 16px;
  }
}

a {
  color: #000;
}


.no-bottom-padding .mat-form-field-wrapper {
  padding-bottom: 0!important;
}
